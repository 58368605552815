import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import appointmentService from "../../../utils/api/v1/appointmentService";

// typings
import { User } from "../../../utils/interfaces/User";

// components
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import LoadingError from "../../../components/Loaders/LoadingError";
import UserInput from "../../../components/Users/UserInput";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";

const EditContainer = styled(Paper)`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .MuiButtonBase-root {
    float: right;
    margin: 20px 10px;
  }
`;

interface Props {
  id: string;
}

const editUser = ({ id }: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [userData, setUserData] = useState<User>({
    id: "",
    email: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    date_of_birth: "",
    gender: "male",
    document_type: "ci",
    document_number: "",
  });

  const fetchUserData = async (): Promise<void> => {
    setLoading(true);
    setError("");
    try {
      const userRequest = await appointmentService.fetchUser(id);
      const data: User = userRequest.data.data;
      data.date_of_birth = data.date_of_birth.substring(0, 10);
      setUserData(data);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
        text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(err.response.data)}`,
        confirmButtonText: "Continuar",
      })
    }
    setLoading(false);
  };

  const submitButton = async (): Promise<void> => {
    setLoading(true);
    try {
      const data = userData;
      const birthDate: Date = new Date(data.date_of_birth);
      birthDate.setHours(12);
      birthDate.setDate(birthDate.getDate() + 1);
      data.date_of_birth = birthDate.toISOString();
      const request = await appointmentService.updateUser(id, data);
      if (request.data.msg === "User updated") {
        await Swal.fire({
          icon: "success",
          title: "Usuario modificado exitosamente",
          confirmButtonText: "Continuar",
          didClose: () => navigate(`/users`)
        });
      }
    } catch (err) {
      console.log(err);
      await Swal.fire({
        icon: "error",
        title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
        text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(err.response.data)}`,
        confirmButtonText: "Continuar",
      });
    }
    setLoading(false);
  };

  const goBackButton = (): void => {
    navigate(`/users/${userData.id}`);
  };

  useEffect((): void => {
    fetchUserData();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && (
        <EditContainer variant="outlined">
          <h1>Editar usuario</h1>
          <UserInput userData={userData} setUserData={setUserData} />
          <ButtonContainer>
            <Link to={`/users/${userData.id}`}>
              <Button variant="text" color="primary">
                Volver
              </Button>
            </Link>
            <Button variant="contained" onClick={submitButton} color="primary">
              Guardar
            </Button>
          </ButtonContainer>
        </EditContainer>
      )}
    </PrivateRoute>
  );
};

export default editUser;
